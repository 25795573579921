import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/demoDashboard';
import DashboardLayout2 from './layouts/businessDashboard';
import DashboardLayout3 from './layouts/staffDashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

// import Blog from './pages/Blog';
// import User from './pages/User';
// import Products from './pages/Products';
import B2CForm from './pages/DemoClients/B2CForm';
import BusinessB2CForm from './pages/BusinessClients/B2CForm';
import StaffB2CForm from './pages/BusinessClients/StaffB2CForm';
import STKForm from './pages/DemoClients/STKForm';
import BusinessSTKForm from './pages/BusinessClients/STKForm';
import StaffSTKForm from './pages/BusinessClients/StaffSTKForm';
import EscrowCreation from './pages/EscrowCreation';

import PaymentLink from './pages/DemoClients/PaymentLink';
import BusinessPaymentLink from './pages/BusinessClients/PaymentLink';
import StaffPaymentLink from './pages/BusinessClients/StaffPaymentLink';
import PaymentInviteLink from './pages/PaymentInviteLink';

import EscrowManage from './pages/EscrowManage';
import M2BForm from './pages/DemoClients/M2BForm';
import BusinessM2BForm from './pages/BusinessClients/M2BForm';
import StaffM2BForm from './pages/BusinessClients/StaffM2BForm';
import KraForm from './pages/KraForm';
import LightBill from './pages/LightBill';
import OtherBill from './pages/DemoClients/OtherBill';
import BusinessOtherBill from './pages/BusinessClients/OtherBill';
import OnboardingSuccess from './pages/OnboardingSuccess';
import BulkPayments from './pages/DemoClients/BulkPayments';
import BusinessBulkPayments from './pages/BusinessClients/BulkPayments';

import RenewSubscription from './pages/BusinessClients/RenewSubscription';
import SetupRecurringPayments from './pages/BusinessClients/RecurringPayments';
import ManageRecurringPayments from './pages/BusinessClients/RecurringPaymentsManage';
import Login from './pages/Login';
import Profile from './pages/DemoClients/Profile';
import BusinessProfile from './pages/BusinessClients/Profile';
import BusinessSettings from './pages/BusinessClients/Settings';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import BusinessRegister from './pages/BusinessRegister';
// import BusinessRegister1 from './pages/BusinessRegister1';
import DynamicQrCode from './pages/DemoClients/DynamicQrCode';
import BusinessDynamicQrCode from './pages/BusinessClients/DynamicQrCode';
import StaffDynamicQrCode from './pages/BusinessClients/StaffDynamicQrCode';
import DashboardApp from './pages/DashboardApp';
import DashboardApp2 from './pages/DashboardApp2';
import DashboardApp3 from './pages/DashboardAppStaff';
import WelcomePage from './pages/WelcomePage';
import WelcomeBuffer from './pages/WelcomeBuffer';
import PhoneDetails from './pages/PhoneDetails';
import ReferralProgram from './pages/ReferralProgram';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import EscrowInviteLink from './pages/EscrowInviteLink';
import EscrowJoinLink from './pages/EscrowJoinLink';
import CalendarEvents from './pages/CalendarEvent';
import KickStarterCreation from './pages/KickStarterCreation';
import KickStarterManage from './pages/KickStarterManage';
import KickStarterInviteLink from './pages/KickStarterInviteLink';
import Alertsender from './pages/Alertsender';
import BulkSms from './pages/DemoClients/Bulksms/BulkSms';
import BusinessBulkSms from './pages/BusinessClients/Bulksms/BulkSms';
import GenerateReceipt from './pages/GenerateReceipt';
import ScannedReceipt from './pages/ScannedReceipt';
import DownloadReceipt from './pages/DownloadReceipt';

// alertsender
// ----------------------------------------------------------------------

export default function Router() {
    // console.log(process.env.REACT_APP_DEPLOYMENT_URL);
    return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        // { path: 'user', element: <User /> },
        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> },
        { path: 'b2cwithdrawals', element: <B2CForm /> },
        { path: 'stkpayments', element: <STKForm /> },
        { path: 'qrcodepayments', element: <DynamicQrCode /> },
        { path: 'm2bwithdrawals', element: <M2BForm /> },
        { path: 'kraremittax', element: <KraForm /> },
        { path: 'profile', element: <Profile /> },
        { path: 'kplclightbill', element: <LightBill /> },
        { path: 'otherbills', element: <OtherBill /> },
        { path: 'escrowcreation', element: <EscrowCreation /> },
        { path: 'manageescrows', element: <EscrowManage /> },
        { path: 'alertsender', element: <Alertsender /> },
        { path: '404', element: <NotFound /> },
        { path: 'generatepaymentlink',element: <PaymentLink /> },
        { path: '*', element: <NotFound /> },     
        { path: 'bulkpayments', element: <BulkPayments /> },
        // { path: 'standingorders', element: <BulkRecurringPayments /> },
        { path: 'bulksms', element: <BulkSms />}
      ],
    },
    {
      path: '/staff-dashboard',
      element: <DashboardLayout3 />,
      children: [
        { path: 'app', element: <DashboardApp3 /> },
        // { path: 'user', element: <User /> },
        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> },
        { path: 'b2cwithdrawals', element: <StaffB2CForm /> },
        { path: 'stkpayments', element: <StaffSTKForm /> },
        { path: 'qrcodepayments', element: <StaffDynamicQrCode /> },
        { path: 'm2bwithdrawals', element: <StaffM2BForm /> },
        // { path: 'kraremittax', element: <KraForm /> },
        // { path: 'profile', element: <Profile /> },
        // { path: 'kplclightbill', element: <LightBill /> },
        // { path: 'otherbills', element: <OtherBill /> },
        // { path: 'escrowcreation', element: <EscrowCreation /> },
        // { path: 'manageescrows', element: <EscrowManage /> },
        // { path: 'alertsender', element: <Alertsender /> },
        { path: '404', element: <NotFound /> },
        { path: 'generatepaymentlink',element: <StaffPaymentLink /> },
        { path: '*', element: <NotFound /> },     
        // { path: 'bulkpayments', element: <BulkPayments /> },
        // { path: 'standingorders', element: <BulkRecurringPayments /> },
        // { path: 'bulksms', element: <BulkSms />}
      ],
    },
    {
      path: '/business-dashboard',
      element: <DashboardLayout2 />,
      children: [
        { path: 'app', element: <DashboardApp2 /> },
        // { path: 'user', element: <User /> },
        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> },
        { path: 'b2cwithdrawals', element: <BusinessB2CForm /> },
        { path: 'stkpayments', element: <BusinessSTKForm /> },
        { path: 'qrcodepayments', element: <BusinessDynamicQrCode /> },
        { path: 'm2bwithdrawals', element: <BusinessM2BForm /> },
        { path: 'kraremittax', element: <KraForm /> },
        { path: 'profile', element: <BusinessProfile /> },
        { path: 'kplclightbill', element: <LightBill /> },
        { path: 'otherbills', element: <BusinessOtherBill /> },
        // { path: 'escrowcreation', element: <EscrowCreation /> },
        // { path: 'manageescrows', element: <EscrowManage /> },
        { path: 'alertsender', element: <Alertsender /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <NotFound /> },     
        { path: 'generatepaymentlink',element: <BusinessPaymentLink /> },
        { path: 'bulkpayments', element: <BusinessBulkPayments /> },
        { path: 'standingorders', element: <SetupRecurringPayments /> },
        { path: 'standingorders-dashboard', element: <ManageRecurringPayments/>},
        { path: 'bulksms', element: <BusinessBulkSms />},
        { path: 'settings', element: <BusinessSettings />}
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'reset-password',
      element: <ResetPassword />,
    },
    {
      path: 'check-kickstarter-records',
      element: <KickStarterManage />,
    },
    {
      path: 'forgotpassword',
      element: <ForgotPassword/>,
    },
    {
      path: 'register',
      element: <Register />,
      // element: <BusinessRegister />,
    },
    {
      path: 'business-registration',
      element: <BusinessRegister />,
    },
    {
      path: 'generate-receipt/:receiptId',
      element: <GenerateReceipt />,
    },
    {
      path: 'scanned-receipt/:receiptId',
      element: <ScannedReceipt />,
    },
    {
      path: 'download-receipt/:receiptId',
      element: <DownloadReceipt />,
    },
    {
      path: 'kickstarter',
      element: <KickStarterCreation/>,
    },
    {
      path: 'kickstarter-invite-link',
      element: <KickStarterInviteLink />, // Assuming you have a component for handling escrow invite links
    },
    {
      path: 'withdrawal-details',
      element: <PhoneDetails />,
    },
    // welcomepage
    {
      path: 'business-onboarding',
      element: <WelcomePage />,
    },
    {
      path: 'business-calendar',
      element: <WelcomeBuffer/>,
    },
    {
      path: 'calendar-event',
      element: <CalendarEvents/>,
    },
    {
      path: 'awaiting-verification',
      element: <OnboardingSuccess />,
    },
    {
      path: 'referral-program',
      element: <ReferralProgram />,
    },
    // OnboardingSuccess
    {
      path: '/',
      element: <Navigate to="/login" />, // Redirect to login if the root path is accessed
    },
    {
      path: '/dashboard',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/dashboard', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <NotFound /> },
      ],
    },
    {
      path: 'escrow-invite-link',
      element: <EscrowInviteLink />, // Assuming you have a component for handling escrow invite links
    },
    {
      path: 'payment-link',
      element: <PaymentInviteLink />, // Assuming you have a component for handling escrow invite links
    },
    {
      path: 'renew-subscription',
      element: <RenewSubscription />, // Assuming you have a component for handling escrow invite links
    },
    {
      path: 'escrow-join-link',
      element: <EscrowJoinLink />, // Assuming you have a component for handling escrow invite links
    },
    {
      path: 'escrow-alertbuyer',
      element: <Alertsender />, // Assuming you have a component for handling escrow invite links
    },
    // { path: 'manageescrows', element: <EscrowManage /> },
    {
      path: '*',
      element: <NotFound />, // Catch all unmatched routes and redirect to /404
    },
  ]);
}
